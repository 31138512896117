import { graphql } from 'gatsby';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import CTASectionTrial from '../../components/CTASectionTrial/CTASectionTrial';
import DefaultPageHeader from '../../components/DefaultPageHeader/DefaultPageHeader';
import FeatureBlock from '../../components/FeatureBlock/FeatureBlock';
import PageSection from '../../components/PageSection/PageSection';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import { PageComponent } from '../../types/gatsby.types';
import './single-feature.scss';

export const query = graphql`
query projectsFeatureQuery {
  singlePost: mdx(fileAbsolutePath: {regex: "/features\/insights/"}) {
    body
    frontmatter {
      title
      description
      overline
      featured_video {
        publicURL
      }
      featured_image {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      color
    }
    id
    slug
  },
  featuredVideo: file(relativePath: {eq: "videos/features/projects/single-feature-featured-video.mp4"}) {
    publicURL
  }
  manyProjectsImage: file(relativePath: {eq: "images/features/projects/many-projects.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  familiarImage: file(relativePath: {eq: "images/features/projects/familiar.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  collaborationImage: file(relativePath: {eq: "images/features/projects/collaboration.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  taskVideo: file(relativePath: {eq: "videos/features/projects/single-feature-task.mp4"}) {
    publicURL
  }

}
`

const SingleFeature: PageComponent = props => {

  // const featuredImage = getImage(props.data.singlePost.frontmatter.featured_image

  return <Observer children={() => (
    <PageTemplate 
      {...props} // always include
      className="SingleFeature SingleFeatureProject"
      title="Projects"
      description="Our intuitive project management system is so simple that you can learn how to use it in just 3 minutes. Our team has pinpointed the features that your business actually needs, so you aren’t overloaded. This means there is no learning curve to overcome and you can reap the benefits of trackster.io from the get go."
      // description={props.data.singlePost.frontmatter.description}
      // title={props.data.singlePost.frontmatter.title}
      // description={props.data.singlePost.frontmatter.description}
      // backgroundColor={ColorPalette.white}
      // foregroundColor={ColorPalette.dark}
    >

      {/* <DefaultPageHeader
        id="FeaturesPageHeader"
        title="Affordable and Effortless Project Management"
        overline="All features"
        // subtitle="Check why trackster is the best solution for your business"
        children={
          <div>
            <p>Trackster is a simple to use, low cost all-in-one time tracking and project management tool for high performing projects.</p>
          </div>
        }
      ></DefaultPageHeader> */}

      <DefaultPageHeader
        id="SingleFeatureProjectsHeader"
        className="SingularPageHeader" // Singular is the WP naming for both default pages + posts + custom post types (as a fallback)
        // title={props.data.singlePost.frontmatter.title}
        overline="trackster feature"
        title="Better Productivity with Projects"
        // subtitle="Visualise your entire team's performance with just a couple of clicks"
        children={
          <div>
            <p>
              Our intuitive project management system is so simple that you can learn how to use it in just 3 minutes. Our team has pinpointed the features that your business actually needs, so you aren’t overloaded. This means there is no learning curve to overcome and you can reap the benefits of trackster from the get go.
            </p>
          </div>
        }
      ></DefaultPageHeader>

      <PageSection id="SingleFeatureArticle SingleBlogArticle">

        {
          props.data.featuredVideo ? 
          // We need the wrapping div to be able to style the video appropriately
          <div className="FeatureBlockVideo" id="SingleFeatureFeaturedVideo"> 
            <video autoPlay muted loop 
            // poster={image.publicURL}
            // TODO: Edge cases (no image / no video, etc)
            >
              <source src={props.data.featuredVideo.publicURL} type="video/mp4"/>
            </video>
          </div>
          : null
          }


        <article className="SingleBlogArticleContent SingleFeatureArticleContent">
          {/* <p className='SingleFeatureDescription'>
            {props.data.singlePost.frontmatter.description}
          </p> */}

          <FeatureBlock
          id="SingleFeatureManyProjects"
          title="As many Project boards as you and your team need"
          image={props.data.manyProjectsImage}
          // image={props.data.liveActivityImage}
          // overline="Filter everything"
          >
            <p>
              The sky is the limit when it comes to trackster Projects. Add as many workspaces are necessary for your organisation and as many colour coded Kanban boards as work demands.
            </p>
          </FeatureBlock>

          <FeatureBlock
          id="SingleFeatureFamiliar"
          title="Familiar, yet, better"
          image={props.data.familiarImage}
          // videoFilePath={props.data.dateRangeVideo.publicURL}
          // overline="Filter everything"
          >
            <p>
              Drag and drop tasks or lists and archive anything like you are used to.
            </p>
            <p>
              You get all the features from Kanban boards out of the box with the additional integrated time tracking benefits that only trackster offers.
            </p>

          </FeatureBlock>

          <FeatureBlock
          id="SingleFeatureCollaboration"
          title="Focus on collaboration"
          // image={props.data.singlePost.frontmatter.featured_image}
          image={props.data.collaborationImage}
          // overline="Filter everything"
          >
            <p>
              Collaboration is at the front and center of trackster purpose, so trackster makes sure you can quickly see who works on what at all times. 
            </p>
          </FeatureBlock>
          
          <FeatureBlock
          id="SingleFeatureTask"
          title="Everything it matters where it makes sense"
          // image={props.data.singlePost.frontmatter.featured_image}
          // image={props.data.tableImage}
          videoFilePath={props.data.taskVideo.publicURL}
          // overline="Filter everything"
          >
            <p>
              Add anything that’s relevant to you and your team directly in the task card. Trackster will keep track of all time tracking information in the task card for your convenience
            </p>
          </FeatureBlock>

          {/* <MDXRenderer>
            {props.data.singlePost.body}
          </MDXRenderer> */}

        </article>


      </PageSection>

      {/* <PageSection id="BlogEntryListSection">
        <h2 className="BlogEntryListSectionHeading">Latest articles</h2>
        <BlogEntryList
        id=""
        allMdx={props.data.allPosts}
        ></BlogEntryList>s
      </PageSection> */}

      <CTASectionTrial
      title="Want to test these and many more features live?"
      />

    </PageTemplate>
  )} />
}

export default SingleFeature;